import React, { Component, useState, useEffect } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import getTitles from '../common/Titles';
import { Form, Input, Grid, Checkbox } from 'semantic-ui-react';
import { PostalCodeLookup } from './StoreFrontBasics';

export const DeliveryAddressForm = (props) => {
    const { t } = useTranslation();
    return <div>
              <Input
                  label={{ basic: true, content: t('companyName') }} name='deliveryCompanyName'
                  value={props.state.deliveryCompanyName} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('addr1') + ' *' }} name='deliveryAddress1'
                  value={props.state.deliveryAddress1} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('addr2') }} name='deliveryAddress2'
                  value={props.state.deliveryAddress2} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('addr3') }} name='deliveryAddress3'
                  value={props.state.deliveryAddress3} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('townCity') }} name='deliveryTown'
                  value={props.state.deliveryTown} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('postcode') + ' *' }} name='deliveryPostcode'
                  value={props.state.deliveryPostcode} onChange={props.onChange} fluid
                />
                <br />
                <Input
                  label={{ basic: true, content: t('country') }} name='deliveryCountry'
                  fluid value={props.state.countryText} disabled={true}
                />
            </div>
  }
  
  export const DeliveryGift = (props) => {
    const { t } = useTranslation();
    return  <React.Fragment>
              <Form.Select
                options={getTitles()}
                onChange={props.onTitleChange}
                placeholder={t('title')}
                selection
                style={{ width: '10px!important' }}
                name='deliveryTitle'
                value={props.state.deliveryTitle}
                error={props.errors?.deliveryTitle}
              />
              <div>
                <Input
                  name='deliveryFirstName'
                  label={{ basic: true, content: t('firstName') + ' *' }}
                  width={4}
                  value={props.state.deliveryFirstName}
                  onChange={props.onChange}
                  placeholder={t('firstName')}
                  error={props.errors?.deliveryFirstName}
                />
                {props.errors?.deliveryFirstName && (
                  <div className='ui pointing red basic label'>
                    {props.errors.deliveryFirstName}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input
                  name='deliveryLastName'
                  label={{ basic: true, content: t('lastName') + ' *' }}
                  value={props.state.deliveryLastName}
                  onChange={props.onChange}
                  placeholder={t('lastName')}
                  fluid
                  error={props.errors?.deliveryLastName}
                />
                {props.errors?.deliveryLastName && (
                  <div className='ui pointing red basic label'>
                    {props.errors.deliveryLastName}
                  </div>
                )}
              </div>
              <br />
              <div></div>
            </React.Fragment>
  
  }
  
export const DeliveryAddress = (props) => {
    const { t } = useTranslation();
    const [deliveryCheckbox, setDeliveryCheckbox] = useState(false);
    const [giftCheckbox, setGiftCheckbox] = useState(false);
  
    const style = {
        container: {
          backgroundColor: props.publication.backgroundColour,
          color: '#fff',
          padding: '1em',
          fontSize: '20px',
          width: '100%',
        },
    }
  
    const onClickDeliveryCheckbox = (e,{value}) => {
      setDeliveryCheckbox(!deliveryCheckbox)
      props.handleDeliveryCheck(e,{value})
    }

    if (deliveryCheckbox) {
  
         return <React.Fragment>
                    <Grid.Row>
                        <div style={style.container}>{t('deliveryAddress')}</div>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column  style={{ padding: '25px' }}>
                            <Checkbox name='deliveryCheckbox' label={t('sameBillingAddr')}
                                checked={deliveryCheckbox}
                                onChange={(e,{value}) => onClickDeliveryCheckbox(e,{value})} />
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
     }
  
    
    return <React.Fragment>
                <Grid.Row>
                    <div style={style.container}>{t('deliveryAddress')}</div>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column style={{ padding: '25px' }}>
                    <Checkbox name='gift' label={t('boughtAsGift')}
                        checked={giftCheckbox}
                        onChange={() => setGiftCheckbox(!giftCheckbox)}
                    />
                    <br />                    
                    {giftCheckbox && 
                        <DeliveryGift 
                            onTitleChange={props.onTitleChange} 
                            onChange={props.onChange}
                            state={props.state} 
                            errors={props.errors} />}

                    <br />
                    <Checkbox
                        name='deliveryCheckbox'
                        label={t('sameBillingAddr')}
                        checked={deliveryCheckbox}
                        onChange={(e,{value}) => onClickDeliveryCheckbox(e,{value})}
                    />
                    <br />
                    <br />
                    {props.showPCLookup &&
                        <PostalCodeLookup 
                            onSelected={(e,{value,text}, pattern) => props.handleDeliveryChange(e,{value,text}, pattern)}/>
                    }
                    <DeliveryAddressForm state={props.state} onChange={props.onChange} />
                </Grid.Column>
                </Grid.Row>
            </React.Fragment>
  }
  