// Main Landing Homepage

import PropTypes from "prop-types";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  // Card,
  Menu,
  Form,
  // Table,
  Responsive,
  Segment,
  Sidebar,
  Dropdown,
  Visibility,
  // Divider,
} from "semantic-ui-react";
import { animateScroll } from "react-scroll";
// import { Link, animateScroll } from "react-scroll";
// import { Link as Link2 } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import RenderFieldLabelAbove from "../common/NormalForm/RenderFieldLabelAbove";
import RenderTextArea from "../common/NormalForm/RenderTextArea";

import "./ContactMe.css";

// import LogoWhite from "../../assets/img/ms200wht.png";
import LogoBlack from "../../assets/img/ms200blk.png";
// import Subscribe from "../../assets/img/magsatndsubscribe.png";
// import RHS from "../../assets/img/img1.png";
// import Plus from "../../assets/img/Magstand-Plus-Logo.png";
// import DETL from "../../assets/img/delogo60.png";
import ReCAPTCHA from "react-google-recaptcha";

// Gets the width of the viewport for responsiveness
const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
const HomepageHeading = ({ mobile }) => <React.Fragment></React.Fragment>;
HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive
        getWidth={getWidth}
        minWidth={Responsive.onlyTablet.minWidth}
        style={{ backgroundUrl: "url(../../assets/img/magsatndsubscribe.png)" }}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment textAlign="center" style={{ padding: "1em 0em" }} vertical>
            <Menu fixed={!fixed} secondary={!fixed} size="small" borderless>
              <Container>
                <Menu.Item>
                  <a href="/">
                    <Image size="small" src={!fixed ? LogoBlack : LogoBlack} />
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href="/#pricing" style={{ color: "#000" }}>
                    Pricing
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href="/#features" style={{ color: "#000" }}>
                    Features
                  </a>
                </Menu.Item>
                <Dropdown text="Resources" pointing className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Header>Magstand Resources</Dropdown.Header>
                    <Dropdown.Item
                      as="a"
                      href="/assets/templates/magstanduploadtemplate.csv"
                      target="_blank"
                    >
                      Data Upload Template
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Header>Payment Resources</Dropdown.Header>
                    <Dropdown.Item
                      as="a"
                      href="https://developer.paypal.com/developer/applications/"
                      target="_new"
                    >
                      Paypal
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="a"
                      href="https://stripe.com"
                      target="_new"
                    >
                      Stripe
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as="a" href="https://magstand.com/contact-us">
                  Contact Us
                </Menu.Item>
                {/* <Menu.Item as='a' href="/howto">
                How It Works
                  </Menu.Item> */}

                <Menu.Item position="right">
                  <Button as="a" href="/login" color="blue">
                    Publisher Login
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
        inverted
      >
        <Sidebar
          as={Menu}
          borderless
          animation="push"
          onHide={this.handleSidebarHide}
          inverted
          color="white"
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a" href="/login">
            Publisher Login
          </Menu.Item>
          <Menu.Item as="a" href="https://magstand.com/contact-us">
            Contact Us
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign="center"
            style={{ minHeight: 50, padding: "1em 0em" }}
            vertical
          >
            <Container style={{ marginTop: "-35px" }}>
              <Menu
                pointing
                secondary
                borderless
                size="large"
                style={{ border: "none" }}
              >
                <Menu.Item onClick={this.handleToggle}>
                  <Icon
                    name="sidebar"
                    color="black"
                    style={{ marginTop: "-35px" }}
                  />
                </Menu.Item>
                <Menu.Item as="a" href="/">
                  <Image size="small" src={LogoBlack} />
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

class ContactMe extends Component {
  state = {
    errors: {},
    name: "",
    phone: "",
    email: "",
    numPubs: "",
    message: "",
    captcha: "",
    showButton: false,
  };
  handleChange = (e, { value }) => {
    this.setState({ numPubs: value });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCapture = (e) => {
    this.setState({ captcha: e, showButton: true });
  };

  handleClick = () => (window.location.href = "/signup");

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      message: this.state.message,
      captcha: this.state.captcha,
      emailResetMessage: "",
    };

    axios.post("/api/order/contactme", userData).then((res) => {
      this.setState({
        emailResetMessage:
          "Thank you for your Contact Request. A member of the Magstand team will be in touch.",
        emailError: "",
      });
    });
  };

  scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  render() {
    let showButton;

    this.state.showButton
      ? (showButton = (
          <div style={{ padding: "10px" }}>
            <Button color="blue" fluid type="submit">
              Submit Request
            </Button>
          </div>
        ))
      : (showButton = null);

    const { errors } = this.state;
    return (
      <ResponsiveContainer>
        <Segment
          style={{
            padding: "2em 0em",
            backgroundColor: "#fff",
            maxWidth: "1140px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          vertical
        >
          <div style={{ marginLeft: "12px" }}>
            <Header as="h1">Get in touch</Header>
            <p style={{ paddingBottom: "2em" }}>
              We can't wait to hear from you, and are here to answer all your
              questions,before, during and after you get your magazine
              storefront.
              <br />
              Complete the form below or email us directly on{" "}
              <a href="mailto:sales@magstand.com">sales@magstand.com</a>
            </p>
          </div>
          <Grid container stackable verticalAlign="bottom">
            <Grid.Row>
              <Grid.Column width={12}>
                <Segment>
                  <Form onSubmit={this.onSubmit}>
                    <RenderFieldLabelAbove
                      placeholder="Name"
                      name="name"
                      label="Name"
                      value={this.state.name}
                      onChange={this.onChange}
                      error={errors.name}
                    />
                    <RenderFieldLabelAbove
                      placeholder="Phone"
                      name="phone"
                      label="Phone Number"
                      value={this.state.phone}
                      onChange={this.onChange}
                      error={errors.phone}
                    />
                    <RenderFieldLabelAbove
                      placeholder="Email"
                      name="email"
                      label="Email"
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                      type="email"
                    />

                    <RenderTextArea
                      name="message"
                      label="Message"
                      value={this.state.message}
                      onChange={this.onChange}
                      error={errors.message}
                    />
                    <ReCAPTCHA
                      style={{ marginLeft: "10px", width: "100%" }}
                      sitekey="6LeYgbwUAAAAAHpc95lHTSZPPmYGuCyq8-BsHltR"
                      onChange={this.onCapture}
                    />
                    {showButton}
                    {this.state.emailResetMessage}
                  </Form>
                </Segment>
              </Grid.Column>

              {/* <Grid.Column width={4}>
                <Header
                  as="h2"
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "normal",
                    marginTop: "0.2em",
                    textAlign: "right",
                    fontFamily: "Libre Franklin",
                  }}
                >
                  <br />
                  1 Accent Park
                  <br />
                  Bakewell Road
                  <br />
                  Orton Southgate
                  <br />
                  Peterborough
                  <br />
                  Cambridgeshire
                  <br />
                  PE2 6XS
                  <br />
                  United Kingdom
                  <br />
                  <br />
                  0207 078 7442
                  <br />
                  <br />
                  <a href="mailto:sales@magstand.com">sales@magstand.com</a>
                </Header>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment vertical style={{ padding: "5em 0em" }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={15}>
                  <Header inverted as="h4" content="About" />
                  <List link inverted>
                    <List.Item
                      as="a"
                      href="https://magstand.com/contact-us"
                      style={{ color: "#000" }}
                    >
                      Contact Us
                    </List.Item>
                    <List.Item
                      as="a"
                      href="/privacy"
                      target="_blank"
                      style={{ color: "#000" }}
                    >
                      Privacy Policy
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    circular
                    size="big"
                    inverted
                    color="blue"
                    name="arrow alternate circle up outline"
                    link
                    onClick={this.scrollToTop}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </ResponsiveContainer>
    );
  }
}

export default ContactMe;
