import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { getPublicationByUrlKey, getPaypalDetails, updatePageViews, } from '../../actions/publicationActions';
import isEmpty from '../../extension/is-empty';
import { createOrder,
  createOrderResult,
  cancelOrder,
  createSTOrder,
  createBackIssueOrder,
} from '../../actions/orderActions';
import { getAccountTel } from '../../actions/accountActions';
import PaypalButton from '../common/PaypalButton/PaypalButton';
import getTitles from '../common/Titles';
import hex2rgba from 'hex2rgba';
import axios from 'axios';
//import StripeCheckout from 'react-stripe-checkout';

import { Button, Table, Checkbox, 
  Grid, Header, Image, Menu, Input, Form, } from 'semantic-ui-react';
import getCountries from '../common/Countries';
import ReactGA, { set } from 'react-ga';
import LoadingOverlay from 'react-loading-overlay';


import DDImage from '../../assets/img/DDMethod.png';
import CCImage from '../../assets/img/card_icons.png';
import PPImage from '../../assets/img/pp-logo-100px.png';


import moment from 'moment';
import './Storefront.css';

import BacsModal  from './BacsModal';
import CardModal  from './CardModal';
import PaymentRequestButton from './PaymentRequestButton';
import { StoreFrontFooter, StoreFrontHeader, ModalProcessingFailed, 
  StoreFrontOptIn, CustomQuestionContainer, PostalCodeLookup, PromoCode,
  PurchaseHeader } from './StoreFrontBasics';
import { Customer } from './Customer';
import { PurchaseTable } from './PurchaseTable';
import { BillingAddress } from './BillingAddress';
import { DeliveryAddressForm, DeliveryGift, DeliveryAddress } from './DeliveryAddress';

// See: https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined


class Storefront extends Component {
  state = {
    isLoading: false,
    modalProcessingFailed: false,
    modalOpen: false,
    modalDirectDebit: false,
    modalCard: false,
    showNewCustomer: true,
    showReturnCustomerDetails: false,
    loggedInCustomerID: null,
    logo: 'FFFFFF-1.png',
    thumbImageName: 'FFFFFF-1.png',

    publisherName: '',
    mainContactEmail: '',
    country: 'GB',
    countryText: '',
    checkboxSelection: '',
    backIssueSelection: [],
    backIssuePurchasePrice: 0,
    email: '',
    email2: '',
    buyer_jwt: null,
    title: '',
    firstName: '',
    forgottenEmail: '',
    emailExisting: '',
    passwordExisting: '',
    mobile: '',
    lastName: '',
    password: '',
    password2: '',
    emailError: '',
    passwordError: '',
    // showForgotten: false,
    subFree: '',
    printSubFree: '',
    printDigSubFree: '',
    discountpercent: '',
    orderMonth: '',
    orderYear: '',
    showPromo: true,
    customQuestionAans: '',
    customQuestionBans: '',
    customQuestionCans: '',
    customQuestionDans: '',
    customQuestionEans: '',
    term: '',
    price: '',
    showUKPrices: false,
    showUSPrices: false,
    showEUPrices: false,
    showCountryPrices: null,
    showPCLookup: false,
    showDeliveryAddress: true,
    showChangeAddressSuccess:false,
    displayDeliveryPostcodes: false,
    displayBillingPostcodes: false,
    showGiftNames: false,
    deliveryCheckbox: false,
    currencySymbol: '£',
    forgottenEmailError: '',
    emailResetMessage: '',
    deliveryCompanyName: '',
    deliveryPCLookup: '',
    deliveryPostcode: '',
    deliveryTitle: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    billingCompanyName: '',
    billingPostcode: '',
    billingPCLookup: '',
    billingAddress1: '',
    billingAddress2: '',
    billingAddress3: '',
    billingTown: '',
    billingState: '',
    deliveryAddress1: '',
    deliveryAddress2: '',
    deliveryAddress3: '',
    deliveryTown: '',
    publication: {},
    bg75: '',
    bg50: '',
    bg25: '',
    bg15: '',
    bg05: '',
    gift: false,
    paypal: '',
    chargeableName: '',
    publishableKey: '',
    secretKey: '',
    user: '',
    pass: '',
    ref: '',
    monthOption: '',
    yearOption: '',
    ccnum: '',
    cvc: '',
    stError: '',
    recurring: false,
    showPaymentButtons: false,
    showSignUpForFreeButton: false,
    showPaymentRequestButton: false,
    showStripe: false,
    showDD: false,
    showpaypal: false,
    currency: '',
    pubOffers: false,
    pubOffersPost: false,
    partnerOffers: false,
    partnerOffersPost: false,
    pubOffersTel: false,
    logofull: false,
    partnerOffersTel: false,
    pubOffersSMS: false,
    partnerOffersSMS: false,
    purchasePrice: '',
    tel: '',
    urlKey: '',
    receiptText: '',
    errors: {},
    sellOverseas: true,
    publisherCountry: '',
    purchaseSubscription: true,
    showBackIssuePaymentButtons: false,
    sampleEdition: '',
    firstSubscritionStartDate: null,
    lastSubscritionEndDate: null,
    accountDets: null,
    mainCurrencySymbol: '£',
  };

  async componentDidMount() {
    var path = this.props.location.pathname;
    path = path.substring(1);
    this.setState({ urlKey: path });
    this.props.getPublicationByUrlKey(path);
    ReactGA.initialize('UA-133495576-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Requesting current country
    const response = await axios.get('/api/external/country')
    const { code, country } = response.data
    this.setState({ country: code, countryText: country });
    this.followCountryChange(code, country);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.publication.publication) {
      if (this.state.logo !== nextProps.publication.publication.logo) {
        document.title =
          'Magstand - ' + nextProps.publication.publication.publicationName;

        // Classify prices per country
        const pricePerCountryList = []
        const countryKeyList = [
          {key: 'UK', prefix: ''  , currency: '£'},
          {key: 'US', prefix: 'US', currency: '$'},
          {key: 'EU', prefix: 'EU', currency: '€'},
        ];
        const { publication } = nextProps.publication;
        for(const countryKey of countryKeyList){
          const priceList = {
            subFree                          : publication[`subFree`],
            printSubFree                     : publication[`printSubFree`],
            printDigSubFree                  : publication[`printDigSubFree`],
            priceOneYear                     : publication[`priceOneYear${countryKey.prefix}`],
            priceSixMonth                    : publication[`priceSixMonth${countryKey.prefix}`],
            priceThreeMonth                  : publication[`priceThreeMonth${countryKey.prefix}`],
            printPriceOneYear                : publication[`printPriceOneYear${countryKey.prefix}`],
            printPriceSixMonth               : publication[`printPriceSixMonth${countryKey.prefix}`],
            printPriceThreeMonth             : publication[`printPriceThreeMonth${countryKey.prefix}`],
            printDigPriceOneYear             : publication[`printDigPriceOneYear${countryKey.prefix}`],
            printDigPriceSixMonth            : publication[`printDigPriceSixMonth${countryKey.prefix}`],
            printDigPriceThreeMonth          : publication[`printDigPriceThreeMonth${countryKey.prefix}`],
            priceOneYearRecurring            : publication[`priceOneYear${countryKey.prefix}Recurring`],
            priceSixMonthRecurring           : publication[`priceSixMonth${countryKey.prefix}Recurring`],
            priceThreeMonthRecurring         : publication[`priceThreeMonth${countryKey.prefix}Recurring`],
            printPriceOneYearRecurring       : publication[`printPriceOneYear${countryKey.prefix}Recurring`],
            printPriceSixMonthRecurring      : publication[`printPriceSixMonth${countryKey.prefix}Recurring`],
            printPriceThreeMonthRecurring    : publication[`printPriceThreeMonth${countryKey.prefix}Recurring`],
            printDigPriceOneYearRecurring    : publication[`printDigPriceOneYear${countryKey.prefix}Recurring`],
            printDigPriceSixMonthRecurring   : publication[`printDigPriceSixMonth${countryKey.prefix}Recurring`],
            printDigPriceThreeMonthRecurring : publication[`printDigPriceThreeMonth${countryKey.prefix}Recurring`],
          }
          pricePerCountryList.push({...countryKey, priceList})  
        }
        nextProps.publication.publication.pricePerCountryList = pricePerCountryList


        this.setState({
          publication: nextProps.publication.publication,
          logo: nextProps.publication.publication.logo,
          logofull: nextProps.publication.publication.logo,
          thumbImageName: nextProps.publication.publication.thumbImageName,
          iosAppLink: nextProps.publication.publication.iosAppLink,
          androidAppLink: nextProps.publication.publication.androidAppLink,
          kindleAppLink: nextProps.publication.publication.kindleAppLink,
          digitalEditionLink:
            nextProps.publication.publication.digitalEditionLink,
          receiptText: nextProps.publication.publication.receiptText,
          mainContactEmail: nextProps.publication.publication.emailAddress,
          ordersEmail: nextProps.publication.publication.orderEmailAddress,
          sampleEdition: nextProps.publication.publication.sampleEdition,
        });
        this.setState({
          bg75: 'rgba(222,224,225, 0.56)',
          bg50: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.5,
          ),
          bg25: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.25,
          ),
          bg15: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.15,
          ),
          bg10: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.1,
          ),
          bg05: hex2rgba(
            nextProps.publication.publication.backgroundColour,
            0.05,
          ),
        });
        this.props.updatePageViews(nextProps.publication.publication._id);
        this.props.getAccountTel(nextProps.publication.publication.user);
      }
    }

    if (nextProps.account.account) {
      if (
        this.state.companyName !==
        nextProps.account.account.accountDets.companyName
      ) {
        const account = nextProps.account.account;
        account.chargeableName = !isEmpty(account.accountDets.directDebitDets)
          ? account.accountDets.directDebitDets.chargeableName
          : '';
        account.publishableKey = !isEmpty(account.accountDets.stripeDets)
          ? account.accountDets.stripeDets.publishableKey
          : '';
        account.secretKey = !isEmpty(account.accountDets.stripeDets)
          ? account.accountDets.stripeDets.secretKey
          : '';
        account.paypal = !isEmpty(account.accountDets.payPalDets)
          ? account.accountDets.payPalDets.payPalString
          : '';
        account.user = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.user
          : '';
        account.pass = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.pass
          : '';
        account.ref = !isEmpty(account.accountDets.secureTradingDets)
          ? account.accountDets.secureTradingDets.ref
          : '';

        this.setState({
          tel: nextProps.account.account.accountDets.telephone,
          publisherName: nextProps.account.account.accountDets.companyName,

          paypal: account.paypal,
          addressOne: nextProps.account.account.accountDets.addressOne,
          addressTwo: nextProps.account.account.accountDets.addressTwo,
          addressThree: nextProps.account.account.accountDets.addressThree,
          postZip: nextProps.account.account.accountDets.postZip,
          mainCurrency: nextProps.account.account.accountDets.mainCurrency,
          chargeableName: account.chargeableName,
          publishableKey: account.publishableKey,
          secretKey: account.secretKey,
          user: account.user,
          pass: account.pass,
          ref: account.ref,
          sellOverseas: nextProps.account.account.accountDets.sellOverseas,
          publisherCountry: nextProps.account.account.accountDets.country,
          accountDets: account.accountDets
        });
        switch (nextProps.account.account.accountDets.mainCurrency) {
          case 'GBP':
            this.setState({ mainCurrencySymbol: '£' });
            break;
          case 'USD':
            this.setState({ mainCurrencySymbol: '$' });
            break;
          case 'EUR':
            this.setState({ mainCurrencySymbol: '€' });
            break;
          default:
            this.setState({ mainCurrencySymbol: '£' });
            break;
        }
      }
    }
  }


  handleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  validateForm = () => {
    const productAndCredentials = this.state.currency !== '' && 
                  this.state.checkboxSelection &&
                  this.state.email !== '' &&
                  this.state.email2 !== '' && 
                  this.state.firstName !== '' &&
                  this.state.lastName !== '' &&
                  this.state.password !== '' && 
                  this.state.password2 !== ''

    const { customQuestionA, customQuestionB, 
      customQuestionC, customQuestionD, customQuestionE} = this.state.publication
    let customQuestionAnswered = true;
    if(customQuestionA && this.state.customQuestionAans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionB && this.state.customQuestionBans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionC && this.state.customQuestionCans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionD && this.state.customQuestionDans !== 'Yes') customQuestionAnswered = false;
    if(customQuestionE && this.state.customQuestionEans !== 'Yes') customQuestionAnswered = false;

    this.setState({ showPaymentRequestButton: productAndCredentials })                  

    // Some dirty code here! Need a better way to handle this but it'll do for now
    if (productAndCredentials && customQuestionAnswered) {
      if (
        this.state.billingAddress1 !== '' &&
        this.state.billingPostcode !== ''
      ) {

        console.log("this.state.showDeliveryAddress ", this.state.showDeliveryAddress)
        console.log("this.state.deliveryCheckbox ", this.state.deliveryCheckbox)

        if (this.state.showDeliveryAddress) {
          if (
            (this.state.showDeliveryAddress &&
              this.state.deliveryCheckbox) ||
            (this.state.deliveryPostcode !== '' &&
              this.state.deliveryAddress1 !== '')
          ) {
            console.log('PP:' + this.state.purchasePrice + " " + this.state.checkboxSelection);

            if (
              // Choose free product
              ( ['FDN', 'FPN', 'FBN'].includes(this.state.checkboxSelection) )
              // Product with discount 100%
              || (this.state.purchasePrice === '0' || this.state.purchasePrice === '0.00' ||
              this.state.purchasePrice.length == 0)
            ) {
              this.setState({ showPaymentButtons: false, showSignUpForFreeButton: true });
            }else if(this.state.checkboxSelection.length > 0){
              this.setState({ showPaymentButtons: true, showSignUpForFreeButton: false });
            }
          } else {
            this.setState({
              //validated: false,
              showPaymentButtons: false,
            });
          }
        } else {
          //this.setState({ validated: true });
          console.log('PP:' + this.state.purchasePrice + " " + this.state.checkboxSelection);

          if (
            // Choose free product
            ( ['FDN', 'FPN', 'FBN'].includes(this.state.checkboxSelection) )
            // Product with discount 100%
            || (this.state.purchasePrice === '0' || this.state.purchasePrice === '0.00' ||
            this.state.purchasePrice.length == 0)
          ) {
            this.setState({ showPaymentButtons: false, showSignUpForFreeButton: true });                          
          }else if(this.state.checkboxSelection.length > 0){
            this.setState({ showPaymentButtons: true, showSignUpForFreeButton: false });
          }
        }
      } else {
        this.setState({
          // validated: false,
          showPaymentButtons: false,
        });
      }
    } else {
      this.setState({
        // validated: false,
        showPaymentButtons: false, showSignUpForFreeButton: false
      });
    } 

    if (
      productAndCredentials &&
      this.state.billingAddress1 !== '' &&
      this.state.billingPostcode !== '' &&
      this.state.backIssueSelection.length > 0
    ) {
      this.setState({
        // validated: true,
        showPaymentButtons: true,
      });
    }
  };

  handleTitleChange = (e, { value }) => {
    this.setState({ title: value });
  };

  handleGiftTitleChange = (e, { value }) => {
    this.setState({ deliveryTitle: value });
  };

  // Country Dropdown Change Handler
  handleCountryChange = (e, { value, text }) => {
    e.persist();
    this.setState({ country: value, countryText: e.target.textContent });
    const { i18n } = this.props;
    const countryOptions = getCountries();
    const selectedCountry = countryOptions.find((c) => c.value === value);
    i18n.changeLanguage(selectedCountry.lang);
    this.followCountryChange(value, text);
  };

  followCountryChange = (value, text) => {
    // GBP
    // ------------
    if( value == 'GB'){
      return this.setState(
        {
          showPCLookup: true,
          showUKPrices: true,
          showEUPrices: false,
          showUSPrices: false,
          showCountryPrices: 'UK',
          currency: 'GBP',
          currencySymbol: '£',
        },
        () => {
          this.validateForm();
        },
      );
    }

    // Dollar
    // ------------
    const dollarCountries = ['US', 'PR', 'EC', 'SV', 'ZW', 
        'GU', 'VI', 'TL', 'WS', 'MP', 'FM', 'PW', 'MH']
    if( dollarCountries.includes(value) ){
      return this.setState(
        {
          showPCLookup: false,
          showUKPrices: false,
          showEUPrices: false,
          showUSPrices: true,
          showCountryPrices: 'US',
          currency: 'USD',
          currencySymbol: '$',
        },
        () => this.validateForm(),
      );
    }



    // EURO
    // ------------
    const euroCountries = ['AD', 'AT', 'BE', 'CY', 'EE', 'FR', 
      'FI', 'DE','GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 
      'MC', 'NL', 'PT', 'SM', 'SK', 'SI', 'ES']
    if( euroCountries.includes(value) ){
      return this.setState(
        {
          showPCLookup: false,
          showUKPrices: false,
          showEUPrices: true,
          showUSPrices: false,
          showCountryPrices: 'EU',
          currency: 'EUR',
          currencySymbol: '€',
        },
        () => this.validateForm()
      );
    }

    // TODO: Change this so it defaults to the publishers currency in My Account
    return this.setState(
      {
        showPCLookup: false,
        showUKPrices: false,
        showEUPrices: false,
        showUSPrices: true,
        showCountryPrices: 'US',
        currency: 'USD',
        currencySymbol: '$',
      },
      () => this.validateForm()      
    );

  };

  // Billing Postcode Dropdown Change Handler
  handleBillingChange = (e, { value, text }, pattern) => {
    e.persist();
    const address = value.split(',');
    if (
      address[0].includes('Limited') ||
      address[0].includes('Ltd') ||
      address[0].includes('Plc') ||
      address[0].includes('LLP')
    ) {
      this.setState(
        {
          billingCompanyName: address[0],
          billingAddress1: address[1],
          billingAddress2: address[2],
          billingAddress3: address[3],
          billingTown: address[5],
          billingPostcode: pattern,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          billingAddress1: address[0],
          billingAddress2: address[1],
          billingAddress3: address[2],
          billingTown: address[5],
          billingPostcode: pattern,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  // Delivery Postcode Dropdown Change Handler
  handleDeliveryChange = (e, { value, text }, pattern) => {
    e.persist();
    const address = value.split(',');
    if (
      address[0].includes('Limited') ||
      address[0].includes('Ltd') ||
      address[0].includes('Plc') ||
      address[0].includes('LLP')
    ) {
      this.setState(
        {
          deliveryCompanyName: address[0],
          deliveryAddress1: address[1],
          deliveryAddress2: address[2],
          deliveryAddress3: address[3],
          deliveryTown: address[5],
          deliveryPostcode: pattern,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          deliveryAddress1: address[0],
          deliveryAddress2: address[1],
          deliveryAddress3: address[2],
          deliveryTown: address[5],
          deliveryPostcode: pattern,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  // Sub selection
  onChangeHandler = (e, { value }) => {
    console.log("value ", value)
    if (
      value !== 'FDN' &&
      value !== 'FPN' &&
      value !== 'FBN' &&
      value.substring(3) !== '0'
    ) {
      if (
        this.state.paypal === '' &&
        this.state.secretKey === '' &&
        // (this.state.serviceUserNumber === '' ||
        //   this.state.serviceUserNumber === undefined) &&
        this.state.ref === ''
      ) {
        var strMessage =
          'Unfortunately payments cannot currently be taken. Please contact ' +
          this.state.publisherName +
          ' ';
        if (this.state.tel !== '' && this.state.tel !== undefined) {
          strMessage += 'on ' + this.state.tel + ' or ';
        }
        strMessage +=
          'by emailing ' +
          this.state.mainContactEmail +
          ' quoting error code: AC74';
        alert(strMessage);
      } else {
        this.setState({
          checkboxSelection: value,
          term: e.target.textContent,
        });
      }
      this.setState({ purchasePrice: value.substring(3) });
    } else {
      this.setState({
        checkboxSelection: value,
        term: e.target.textContent,
      });
      this.setState({ purchasePrice: '0', showDD: false });
    }
    if (value.substring(2, 3) === 'R') {
      this.setState({ recurring: true });
    } else {
      this.setState({ recurring: false });
    }
    if (
      e.target.textContent.includes('Print') ||
      e.target.textContent.includes('Bundle')
    ) {
      this.setState({
          showDeliveryAddress: true,
        },
        () => {
          this.validateForm();
        },
      );
    } else {
      this.setState(
        {
          showDeliveryAddress: false,
        },
        () => {
          this.validateForm();
        },
      );
    }
  };

  onBackIssueHandler = (e, data) => {
    let selected = [...this.state.backIssueSelection];

    if (data.checked) {
      selected.push(data.value);
      this.setState((prevState) => ({
        backIssuePurchasePrice: prevState.backIssuePurchasePrice + data.price,
      }));
    } else {
      var index = selected.indexOf(data.value);
      this.setState((prevState) => ({
        backIssuePurchasePrice: prevState.backIssuePurchasePrice - data.price,
      }));
      selected.splice(index, 1);
    }
    this.setState({ backIssueSelection: selected }, () => {
      this.validateForm();
    });
  };



  handlePaymentChange = (e, { value }) => {
    const PAYMENT_TYPE = { PAYPAL: '1', CREDIT_CARD: '2', DIRECT_DEBIT: '3'};
    this.setState({ paymentType: value });
    switch (value) {
      case PAYMENT_TYPE.PAYPAL:
        this.setState({
          showpaypal: true,
          showDD: false,
          showStripe: false,
        });
        break;
      case PAYMENT_TYPE.CREDIT_CARD:
        this.setState({
          showpaypal: false,
          showDD: false,
          showStripe: true,
        });
        break;
      case PAYMENT_TYPE.DIRECT_DEBIT:
        this.setState({
          showpaypal: false,
          showDD: true,
          showStripe: false,
        });
        break;
      default:
        break;
    }
  };

  // Delivery Checkbox Handler
  handleDeliveryCheck = (e, { value }) => {
    this.setState(
      (prevState) => ({ deliveryCheckbox: !prevState.deliveryCheckbox }),
      () => {
        this.validateForm();
      },
    );
  };

  handleGiftCheck = (e, { value }) => {
    this.setState(
      (prevState) => ({
        gift: !prevState.gift,
        showGiftNames: !prevState.showGiftNames,
      }),
      () => {
        this.validateForm();
      },
    );
  };

  // Generic Change Handler
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateForm();
    });
  };

  onApplyPromoToPrice = (promocodeObj) => {
    const {_id, discount} = promocodeObj
    const discountedValue =
      (this.state.purchasePrice * discount) / 100;
    let newValue = this.state.purchasePrice - discountedValue;
    newValue = newValue.toFixed(2);
    this.setState(
      {
        purchasePrice: newValue,
        discountpercent: discount,
        promoid: _id,
        showPromo: false,
      },
      () => {
        this.validateForm();
      },
    );

  };


  performLoginCredentials = async (e, emailExisting, passwordExisting ) => {
    e.preventDefault();
    try{
      this.setState({ emailExisting, passwordExisting })
      const body = {
        email: emailExisting,
        password: passwordExisting,
        publicationID: this.state.publication._id,
      };
      const response = await axios.post('/api/order/buyerlogin', body)
      if( !response ) return   

      const { lastOrder } = response.data

      // Cover free case
      let defaultCheckboxSelection = '1PN' + this.state.publication.printPriceOneYear;
      let defaultPurchasePrice = this.state.publication.printPriceOneYear;
      if(this.state.publication.printPriceOneYear == '0' || this.state.publication.printPriceOneYear == '0.00' ||
      this.state.publication.printPriceOneYear.length == 0){
        defaultCheckboxSelection = 'FPN'
        defaultPurchasePrice = '0'
      }
        


      this.setState(
        {
          showNewCustomer: false,
          showReturnCustomerDetails: true,
          loggedInCustomerID: response.data.id,
          title: response.data.title,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          password: response.data.password,
          password2: response.data.password,
          email: response.data.email,
          email2: response.data.email,
          buyer_jwt: response.data.jwt,

          billingCompanyName: lastOrder.billingCompanyName?lastOrder.billingCompanyName:'',
          billingPostcode: lastOrder.billingPostcode?lastOrder.billingPostcode:'',
          billingAddress1: lastOrder.billingAddressOne?lastOrder.billingAddressOne:'',
          billingAddress2: lastOrder.billingAddressTwo?lastOrder.billingAddressTwo:'',
          billingAddress3: lastOrder.billingAddressThree?lastOrder.billingAddressThree:'',
          billingTown: lastOrder.billingCity?lastOrder.billingCity:'',
          billingState: lastOrder.billingState?lastOrder.billingState:'',

          showDeliveryAddress: false,
          //checkboxSelection: '',
          // showDeliveryAddress: lastOrder.shippingAddressOne!=null,
          checkboxSelection: lastOrder.shippingAddressOne!=null?defaultCheckboxSelection:this.state.checkboxSelection,
          purchasePrice: lastOrder.shippingAddressOne!=null?defaultPurchasePrice:this.state.purchasePrice,
          deliveryCompanyName: lastOrder.shippingCompanyName?lastOrder.shippingCompanyName:'',
          deliveryPostcode: lastOrder.shippingPostcode?lastOrder.shippingPostcode:'',
          deliveryTitle: lastOrder.shippingTitle?lastOrder.shippingTitle:'',
          deliveryFirstName: lastOrder.shippingFirstName?lastOrder.shippingFirstName:'',
          deliveryLastName: lastOrder.shippingLastName?lastOrder.shippingLastName:'',
          deliveryAddress1: lastOrder.shippingAddressOne?lastOrder.shippingAddressOne:'',
          deliveryAddress2: lastOrder.shippingAddressTwo?lastOrder.shippingAddressTwo:'',
          deliveryAddress3: lastOrder.shippingAddressThree?lastOrder.shippingAddressThree:'',
          deliveryTown: lastOrder.shippingCity?lastOrder.shippingCity:'',

          firstSubscritionStartDate: response.data.firstSubStartDate?moment(new Date(response.data.firstSubStartDate)).format('DD/MM/YYYY'):'-',
          lastSubscritionEndDate: moment(new Date(lastOrder.subEndDate)).format('DD/MM/YYYY'),

          modalLogin: false
        },
        () => {
          this.validateForm();
        });
        return true
    }catch(err){
      this.setState({ errors: err.response.data })
      return false
    }


  }


  onLogout = () => {
    let defaultCheckboxSelection = '1PN' + this.state.publication.printPriceOneYear;
    if(this.state.publication.printPriceOneYear = '0' || this.state.publication.printPriceOneYear == '0.00' ||
    this.state.publication.printPriceOneYear.length == 0)
      defaultCheckboxSelection = 'FPN'
    this.setState(
      {
        showNewCustomer: true,
        showReturnCustomerDetails: false,
        loggedInCustomerID: null,
        title: '',
        firstName: '',
        lastName: '',
        password: '',
        password2: '',
        email: '',
        email2: '',

        billingCompanyName: '',
        billingPostcode: '',
        billingAddress1: '',
        billingAddress2: '',
        billingAddress3: '',
        billingTown: '',
        billingState: '',

        showDeliveryAddress: true,
        checkboxSelection: defaultCheckboxSelection,
        deliveryCompanyName: '',
        deliveryPostcode: '',
        deliveryTitle: '',
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryAddress1: '',
        deliveryAddress2: '',
        deliveryAddress3: '',
        deliveryTown: '',

        firstSubscritionStartDate: '',
        lastSubscritionEndDate: '',
        showPaymentRequestButton: false
      }
    );

  }


  onSaveAddress = async () => {
    try{
      this.setState({showChangeAddressSuccess: false})
      const headers = { 'Authorization': `Bearer ${this.state.buyer_jwt}`}
      const body = { 
        shippingCompanyName: this.state.deliveryCompanyName,
        shippingPostcode: this.state.deliveryPostcode,
        shippingTitle: this.state.deliveryTitle,
        shippingFirstName: this.state.deliveryFirstName,
        shippingLastName: this.state.deliveryLastName,
        shippingAddressOne: this.state.deliveryAddress1,
        shippingAddressTwo: this.state.deliveryAddress2,
        shippingAddressThree: this.state.deliveryAddress3,
        shippingCity: this.state.deliveryTown,        
      }
      await axios.post('/api/order/buyer/address', body, { headers })
      this.setState({showChangeAddressSuccess: true})
    }catch(err){
      this.setState({ errors: err.response.data })
    }
  }

  setupFieldsToSend = (payment) => {
    const paymentData = {
      receiptText: this.state.receiptText,
      logo: this.state.logo,
      iosAppLink: this.state.iosAppLink,
      androidAppLink: this.state.androidAppLink,
      kindleAppLink: this.state.kindleAppLink,
      digitalEditionLink: this.state.digitalEditionLink,
      tel: this.state.tel,
      publisherName: this.state.publisherName,
      mainContactEmail: this.state.mainContactEmail,
      orderEmailAddress: this.state.publication.orderEmailAddress,
      addressOne: this.state.addressOne,
      addressTwo: this.state.addressTwo,
      addressThree: this.state.addressThree,
      postZip: this.state.postZip,
      recurring: this.state.recurring,
      country: this.state.country,
      checkboxSelection: this.state.checkboxSelection,
      maincurrency: this.state.currency,
      currency: this.state.currencySymbol,
      purchasePrice: this.state.purchaseSubscription
        ? this.state.purchasePrice
        : this.state.backIssuePurchasePrice,
      title: this.state.title,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      mobile: this.state.mobile,
      deliveryCompanyName: this.state.deliveryCompanyName,
      deliveryAddress1: this.state.deliveryAddress1,
      deliveryAddress2: this.state.deliveryAddress2,
      deliveryAddress3: this.state.deliveryAddress3,
      deliveryTown: this.state.deliveryTown,
      deliveryPostcode: this.state.deliveryPostcode,
      deliveryCheckbox: this.state.deliveryCheckbox,
      billingCompanyName: this.state.billingCompanyName,
      billingAddress1: this.state.billingAddress1,
      billingAddress2: this.state.billingAddress2,
      billingAddress3: this.state.billingAddress3,
      billingTown: this.state.billingTown,
      billingState: this.state.billingState,
      billingPostcode: this.state.billingPostcode,
      promoCode: this.state.promoCode,
      promoid: this.state.promoid,
      customQuestionAans: this.state.customQuestionAans,
      customQuestionBans: this.state.customQuestionBans,
      customQuestionCans: this.state.customQuestionCans,
      customQuestionDans: this.state.customQuestionDans,
      customQuestionEans: this.state.customQuestionEans,
      pubOffers: this.state.pubOffers,
      pubOffersPost: this.state.pubOffersPost,
      partnerOffers: this.state.partnerOffers,
      partnerOffersPost: this.state.partnerOffersPost,
      pubOffersTel: this.state.pubOffersTel,
      partnerOffersTel: this.state.partnerOffersTel,
      pubOffersSMS: this.state.pubOffersSMS,
      partnerOffersSMS: this.state.partnerOffersSMS,
      publicationID: this.state.publication._id,
      discountPercent: this.state.discountpercent,
      ccemail: this.state.publication.ccemail,
      emailText: this.state.publication.emailText,
      emailEnd: this.state.publication.emailEnd,
      payment: payment,
      urlKey: this.state.urlKey,
      loggedInCustomerID: this.state.loggedInCustomerID,
      secretKey: this.state.secretKey,
      ddaccountholder: this.state.ddaccountholder,
      ddsortcode: this.state.ddsortcode,
      ddaccountnumber: this.state.ddaccountnumber,
      onum: this.state.onum,
      gift: this.state.gift,
      deliveryTitle: this.state.deliveryTitle,
      deliveryFirstName: this.state.deliveryFirstName,
      deliveryLastName: this.state.deliveryLastName,
      publicationName: this.state.publication.publicationName,
      backIssues: this.state.backIssueSelection,
    };

    return paymentData;
  };

  payPalSubmit = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.createOrder(paymentData, this.props.history);
  };

  payPayCancel = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.cancelOrder(paymentData, this.props.history);
  };


  onPaymentRequestButtonSubmit = (paymentIntent) => {
    const paymentData = this.setupFieldsToSend()
    paymentData.isPaymentRequest = true
    localStorage.setItem('paymentData', JSON.stringify(paymentData))
    window.location = `/ordersuccess?url=interior-designer&payment_intent=${paymentIntent.id}`    
  }

  onSignUpForFreeButtonSubmit = () => {
    const paymentData = this.setupFieldsToSend()
    localStorage.setItem('paymentData', JSON.stringify(paymentData))
    window.location = `/ordersuccess?url=interior-designer&payment_intent=free`    
  }


  payPalBackIssueSubmit = (payment) => {
    const paymentData = this.setupFieldsToSend(payment);
    this.props.createBackIssueOrder(paymentData, this.props.history);
  };

  stripeBackIssueSubmit = (token) => {
    const paymentData = this.setupFieldsToSend();
    paymentData.stripeToken = token;
    this.props.createBackIssueOrder(paymentData, this.props.history);
  };


  render() {
    const { showPCLookup } = this.state;
    const { showUKPrices, showUSPrices, showEUPrices, checkboxSelection } = this.state;
    const { t } = this.props;

    let countryOptions = getCountries();
    if (!this.state.sellOverseas)
      countryOptions = countryOptions.filter(
        (v) => v.key === this.state.publisherCountry,
      );

    const { errors } = this.state;
    const publication = this.state.publication;
    const titleOptions = getTitles();

    const onSuccess = (payment) => {
      this.payPalSubmit(payment);
    };

    const onError = (error) =>
      console.log('Erroneous payment OR failed to load script!', error);

    const onCancel = (data) => {
      this.payPayCancel(data);
    };

    const onBackIssueSuccess = (payment) => {
      this.payPalBackIssueSubmit(payment);
    };

    const onModalCard = () => {
      const paymentData = this.setupFieldsToSend()
      localStorage.setItem('paymentData', JSON.stringify(paymentData))
      this.setState({ modalCard: true })
    }

    const onModalDirectDebit = () =>  {
      const paymentData = this.setupFieldsToSend()
      localStorage.setItem('paymentData', JSON.stringify(paymentData))
      this.setState({modalDirectDebit:true})
    }

    const style = {
      background: {
        backgroundColor: this.state.bg75,
        // padding: '5em 0em'
      },
      magbackground: {
        backgroundColor: '#f3f3f3',
        //backgroundColor: this.state.bg10,
        marginTop: '-15px',
        marginBottom: '-20px',
        paddingTop: '3em',
        paddingBottom: '3em',
      },
      changecolor: {
        color: '#828282',
        fontSize: '18px',
      },
      menu: {
        backgroundColor: '#333',
      },
      container: {
        backgroundColor: publication.backgroundColour,
        color: '#fff',
        padding: '1em',
        fontSize: '20px',
        width: '100%',
      },
      logocontainer: {
        backgroundColor: '#fff',
        color: '#fff',
        padding: '2em',
        // marginTop: "-30px",
        fontSize: '20px',
        width: '100%',
      },
      tableHeader: {
        backgroundColor: publication.backgroundColour,
        padding: '2em',
        fontSize: '17px',
        color: '#fff',
      },
      tableContentdigital: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: this.state.bg05,
      },
      tableContentprint: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: '#f3f3f3',
      },
      tableContentbundle: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: this.state.bg15,
      },
    };
    let deliveryAddress;
    let purchaseOptions;
    let paymentDetails;
    let showDiscount;
    let paymentButton;
    let giftNameContent;

    // -------------------------
    // INIT: Payment Button
    // -------------------------
    console.log("CheckboxSelection ", this.state.checkboxSelection)
    if (this.state.showpaypal) {
      if (this.state.purchaseSubscription) {
        paymentButton = (
          <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
            <PaypalButton
              client={{ production: this.state.paypal, }}
              env={'production'}
              commit={true}
              currency={this.state.currency}
              total={this.state.purchasePrice}
              onSuccess={onSuccess}
              onError={onError}
              onCancel={onCancel}
            />
          </div>
        );
      } else {
        paymentButton = (
          <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
            <PaypalButton
              client={{ production: this.state.paypal, }}
              env={'production'}
              commit={true}
              currency={this.state.currency}
              total={this.state.backIssuePurchasePrice}
              onSuccess={onBackIssueSuccess}
              onError={onError}
              onCancel={onCancel}
            />
          </div>
        );
      }
    } else if(this.state.showStripe && this.state.secretKey && this.state.purchasePrice>0 ) {
        paymentButton = (
          <Button
            type='button'
            onClick={() => onModalCard()}
            size='massive'
            color='black'
            style={{ background: '#5cb123'}}
            className="dd-button">
            Pay now
          </Button>
        );      
    }else if (this.state.showDD && this.state.purchasePrice>0) {
      paymentButton = (
        <Button
          type='button'
          onClick={() => onModalDirectDebit()}
          size='massive'
          color='black'
          style={{ background: '#5cb123'}}
          className="dd-button">
          Pay now
        </Button>
      );
    }else if( this.state.showSignUpForFreeButton ){
      paymentButton = (
        <Button
          type='button'
          onClick={() => this.onSignUpForFreeButtonSubmit()}
          size='massive'
          color='black'
          style={{ background: '#5cb123'}}
          className="dd-button">
          Sign Up
        </Button>
      );    
    }else{
      paymentButton = (
        <div style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px', }} >
          <Button disabled size='massive' color='black'>
            {t('completeForm')}
          </Button>
        </div>
      );
    }




    // -------------------------
    // END: Payment Button
    // -------------------------


    if (this.state.purchaseSubscription) {
      purchaseOptions = <PurchaseTable 
                          country={this.state.showCountryPrices}
                          publication={publication}
                          checkboxSelection={checkboxSelection} 
                          onChange={(e, {value}) => this.onChangeHandler(e, {value})}  />
    } else {
      if (showEUPrices || showUKPrices || showUSPrices) {
        purchaseOptions = (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                ></Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={7}
                >
                  {t('backIssue')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                >
                  {t('price')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={3}
                >
                  {t('ppPrice')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign='center'
                  style={style.tableHeader}
                  width={2}
                >
                  {t('buy')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.publication.publication.backIssues.map(
                (backIssue) => (
                  <Table.Row key={backIssue._id}>
                    <Table.Cell textAlign='center' style={style.tableContentprint}>
                      <img src={backIssue.coverImg} style={{ width: '100%' }}
                        alt='' />
                    </Table.Cell>
                    <Table.Cell textAlign='center' style={style.tableContentprint}>
                      <h3 style={{ fontSize: '18px' }}>{backIssue.volume}</h3>
                      <p style={{ fontSize: '13px' }}>
                        {backIssue.description}
                      </p>
                    </Table.Cell>
                    <Table.Cell textAlign='center' style={style.tableContentprint}>
                      <span style={{ fontSize: '18px' }}>
                        {showUKPrices
                          ? '£' + backIssue.price
                          : showEUPrices
                          ? '€' + backIssue.priceEU
                          : '$' + backIssue.priceUS}
                      </span>
                    </Table.Cell>
                    <Table.Cell textAlign='center' style={style.tableContentprint}>
                      <span style={{ fontSize: '18px' }}>
                        {showUKPrices
                          ? '£' + backIssue.ppPrice
                          : showEUPrices
                          ? '€' + backIssue.ppPriceEU
                          : '$' + backIssue.ppPriceUS}
                      </span>
                    </Table.Cell>
                    <Table.Cell textAlign='center' style={style.tableContentprint}>
                      <Form.Field>
                        <Checkbox
                          style={style.changecolor}
                          name='backIssueSelection'
                          value={backIssue._id}
                          onChange={this.onBackIssueHandler}
                          price={
                            showUKPrices
                              ? backIssue.price + backIssue.ppPrice
                              : showEUPrices
                              ? backIssue.priceEU + backIssue.ppPriceEU
                              : backIssue.priceUS + backIssue.ppPriceUS
                          }
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        );
      }
    }


    let payPalRadio;
    let dDRadio;
    let ccradio;

    this.state.paypal && this.state.checkboxSelection.substring(2, 3) !== 'R'
      ? (payPalRadio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='1'
              checked={this.state.paymentType === '1'} onChange={this.handlePaymentChange}/>
              {' '}
              <img src={PPImage} alt=''
                style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px', }} />
            <br />
            <br />
          </React.Fragment>
        ))
      : (payPalRadio = null);


    this.state.publishableKey || this.state.user 
      ? (ccradio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='2'
              checked={this.state.paymentType === '2'}
              onChange={this.handlePaymentChange}/>{' '}
            <img src={CCImage} alt=''
              style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px', }}/>
            <br />
            <br />
          </React.Fragment>
        ))
      : (ccradio = null);

    const directDebitDets = this.state.accountDets?this.state.accountDets.directDebitDets:null
    directDebitDets && directDebitDets.selectedDD && this.state.currency === 'GBP'
      ? (dDRadio = (
          <React.Fragment>
            <Checkbox radio name='paymentType' value='3'
              checked={this.state.paymentType === '3'}
              onChange={this.handlePaymentChange}/>{' '}
            <img src={DDImage} alt=''
              style={{ marginLeft: '10px', verticalAlign: 'middle', width: '100px',}} />
            <br />
            <br />
          </React.Fragment>
        ))
      : (dDRadio = null);

    if (this.state.purchaseSubscription) {
      paymentDetails = (
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('subscription')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.publication.publicationName}
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ borderTop: 'none!important' }}>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('subscriptionTerm')}:
              </Table.Cell>
              <Table.Cell style={{ border: 'none!important' }}>
                {this.state.term}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('price')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.purchasePrice !== 'FREE'
                  ? this.state.currencySymbol
                  : null}
                {this.state.purchasePrice}
              </Table.Cell>
            </Table.Row>
            {this.state.discountpercent !== '' &&
              <Table.Row>
                <Table.Cell>Discount</Table.Cell>
                <Table.Cell>{this.state.discountpercent}%</Table.Cell>
              </Table.Row>
            }

            <Table.Row>
              <Table.Cell>{t('deliveryCountry')}:</Table.Cell>
              <Table.Cell>{this.state.countryText}</Table.Cell>
            </Table.Row>
            {this.state.showPromo &&
              <PromoCode 
                onApplyPromo={(promocodeObj) => this.onApplyPromoToPrice(promocodeObj)} 
                publication={this.state.publication}
                lengthType={this.state.checkboxSelection[0]}
                productType={this.state.checkboxSelection[1]} />
            }
          </Table.Body>
        </Table>
      );
    } else {
      paymentDetails = (
        <Table basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('backIssues')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.backIssueSelection.length} back issue(s) selected.
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ borderTop: 'none!important' }}>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('purchaseTerm')}:
              </Table.Cell>
              <Table.Cell style={{ border: 'none!important' }}>
                {t('oneTimePurchase')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {t('totalPrice')}:
              </Table.Cell>
              <Table.Cell style={{ borderTop: 'none!important' }}>
                {this.state.currencySymbol}{' '}
                {this.state.backIssuePurchasePrice.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('deliveryCountry')}:</Table.Cell>
              <Table.Cell>{this.state.countryText}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    
    return (
      <LoadingOverlay active={this.state.isLoading} spinner
        text='Processing your request...' >

      <div style={{ height: '100vh', overflowX: 'hidden', overflowY: 'auto' }}>


      <Form>
        <div style={style.background}>
          <Grid
            container
            stackable
            verticalAlign='middle'
            style={{ backgroundColor: '#f3f3f3', margin: 0 }}
          >
            
            <StoreFrontHeader 
              publisherName={this.state.publisherName}
              logo={this.state.logo}
              sampleEdition={this.state.sampleEdition}
              thumbImageName={this.state.thumbImageName}
              publication={this.state.publication}
              showNewCustomer={this.state.showNewCustomer}
              style={style}

              onLoginClick={(e, email, password) => this.performLoginCredentials(e, email, password)}
              emailExisting={this.state.emailExisting}
              errors={this.state.errors}
            />

            {/* Purchase Table */}
            <PurchaseHeader 
                purchaseSubscription={this.state.purchaseSubscription} 
                publication={publication}
                onPurchaseSubscription={(value) => this.setState({purchaseSubscription:value})} />
            <Grid.Row>
              <Grid.Column>
                <div style={{ padding: '25px' }}>
                  <Form.Select
                    options={countryOptions}
                    label={t('selectCountry')}
                    onChange={this.handleCountryChange}
                    placeholder={t('selectCountry')}
                    fluid
                    size='large'
                    selection
                    name='country'
                    value={this.state.country}
                    error={errors.country}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{purchaseOptions}</Grid.Column>
            </Grid.Row>


            <Customer showReturnCustomerDetails={this.state.showReturnCustomerDetails}
              onLoginClick={(e, email, password) => this.performLoginCredentials(e, email, password)}
              errors={this.state.errors} 
              publication={this.state.publication} 
              onChange={e => this.onChange(e)} 
              onLogout={this.onLogout}
              {...this.state} />

            {this.state.showReturnCustomerDetails &&            
            <Grid.Row verticalAlign='top'>              
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <div style={{ padding: '25px' }}>
                      <Header as='h3'>Delivery Address</Header>
                      {showPCLookup &&
                        <PostalCodeLookup 
                          onSelected={(e, {value,text}, pattern) => this.handleDeliveryChange(e,{text, value}, pattern)}/>
                      }
                      <DeliveryAddressForm state={this.state} onChange={this.onChange} />
                      <br />
                      <br />
                      <div>
                        {this.state.showChangeAddressSuccess?
                        <div className="storefront-alert">                            
                          Address Changed Successfully
                        </div>                  
                        :null}
                      </div>
                      <br />
                      <div>
                        <Button type='button' fluid color='black' 
                          onClick={this.onSaveAddress} style={{ width: '50%', margin: '0 auto'}}>
                          Change Address
                        </Button>
                      </div>
    
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            }

            <BillingAddress publication={publication} 
              showPCLookup={showPCLookup} 
              handleBillingChange={(e,{value,text}, pattern) => this.handleBillingChange(e,{value,text}, pattern)}
              onChange={this.onChange}
              state={this.state}
              />


            {this.state.showDeliveryAddress && 
              <DeliveryAddress 
                showPCLookup={showPCLookup}
                state={this.state} 
                onTitleChange={(e, {value}) => this.setState({ deliveryTitle: value }) }
                onChange={this.onChange} 
                handleDeliveryChange={this.handleDeliveryChange}
                handleDeliveryCheck={this.handleDeliveryCheck}
                publication={publication}
                errors={errors} />
            }

            
            <Grid.Row>
              <div style={style.container}>{t('paymentDetails')}</div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <Image
                  src={this.state.thumbImageName}
                  style={{ width: '150px' }}
                  centered
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Grid.Row>
                  {paymentDetails}
                  {this.state.showPaymentButtons && this.state.purchasePrice>0 ? (
                    <React.Fragment>
                      <p>{t('choosePayment')}</p>
                      {payPalRadio}
                      {ccradio}
                      {dDRadio}
                    </React.Fragment>
                  ) : null}
                  <div className={this.state.showPaymentRequestButton>0?'':'d-none'}
                    style={{ marginRight: '25px', marginTop: '10px', maxWidth: '200px' }}>
                    
                    {this.state.showPaymentRequestButton && this.state.purchasePrice>0?
                    <PaymentRequestButton 
                      //stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent, true)}
                      stripeSubmit={(paymentIntent) => this.onPaymentRequestButtonSubmit(paymentIntent)}
                      secretKey={this.state.secretKey}
                      publishableKey={this.state.publishableKey} 
                      accountDets={this.state.accountDets} 
                      amount={this.state.purchasePrice * 100}/>                
                    :null}
                    
                  </div>
                  {this.state.showPaymentRequestButton?'':''}
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={3}>&nbsp;</Grid.Column>
            </Grid.Row>



            <CustomQuestionContainer 
              publication={this.state.publication} 
              errors={this.state.errors} 
              onChange={(customQuestionAnswer) => {
                this.setState({ ...customQuestionAnswer })
                // Give a little bit of time to update the state
                setTimeout( () => this.validateForm(), 300)
                
              }}
              />
            <Grid.Row>
              <Grid.Column width={8}>
                <StoreFrontOptIn publication={this.state.publication} 
                  onChange={(optIn) => this.setState({...optIn })} />
              </Grid.Column>
              <Grid.Column width={8} textAlign='right' verticalAlign='bottom'>
                <div>
                  <p className="terms-desktop">
                    <Trans i18nKey='acceptPrivacyPolicy'>
                      By clicking the button you
                      <br />
                      accept the <a href='/privacy'>Privacy Policy</a>
                    </Trans>
                  </p>
                  <p className="terms-mobile">
                    <Trans i18nKey='acceptPrivacyPolicy'>
                      By clicking the button you                      
                      accept the <a href='/privacy'>Privacy Policy</a>
                    </Trans>
                  </p>
                  {paymentButton}

                </div>

              </Grid.Column>
            </Grid.Row>

            <StoreFrontFooter
              tel={this.state.tel}
              mainContactEmail={this.state.mainContactEmail}
              publication={this.state.publication}
              publisherName={this.state.publisherName}
              addressOne={this.state.addressOne}
              addressTwo={this.state.addressTwo}
              addressThree={this.state.addressThree}
              postZip={this.state.postZip}
              style={style}
              />

          </Grid>
        </div>
      </Form>
      
      </div>



      <ModalProcessingFailed 
        open={this.state.modalProcessingFailed} 
        onClose={() => this.setState({ modalProcessingFailed: false})} />
      
      <BacsModal open={this.state.modalDirectDebit} 
        onClose={() => this.setState({modalDirectDebit:false})} 
        onStripeError={() => this.setState({ modalProcessingFailed: true })}
        // stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent)}
        secretKey={this.state.secretKey}
        publishableKey={this.state.publishableKey} 
        accountDets={this.state.accountDets} 
        amount={this.state.purchasePrice * 100}/>

      <CardModal open={this.state.modalCard} 
        onClose={() => this.setState({modalCard:false})} 
        onStripeError={() => this.setState({ modalProcessingFailed: true })}
        // stripeSubmit={(paymentIntent) => this.stripeSubmit(paymentIntent)}
        secretKey={this.state.secretKey}
        publishableKey={this.state.publishableKey} 
        accountDets={this.state.accountDets} 
        amount={this.state.purchasePrice * 100} />

      </LoadingOverlay>
    );
  }
}

Storefront.propTypes = {
  getAccountTel: PropTypes.func.isRequired,
  getPaypalDetails: PropTypes.func.isRequired,
  getPublicationByUrlKey: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  createOrderResult: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  updatePageViews: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  publication: state.publication,
  auth: state.auth,
  errors: state.errors,
  account: state.account,
});

export default connect(mapStateToProps, {
  getPublicationByUrlKey,
  getPaypalDetails,
  getAccountTel,
  createOrder,
  createOrderResult,
  createSTOrder,
  cancelOrder,
  updatePageViews,
  createBackIssueOrder,
})(withTranslation()(withRouter(Storefront)));
